@use "sass:math";

@use '../../styles/yr' as *;

$gutter-width-small: size(2);
$gutter-width-large: size(5);
$peek-width: size(3);
$layout-gutter-width: size($layout-size-horizontal);
$total-layout-gutter-width: 2 * $layout-gutter-width;

// The gutter is a little larger when all cards are visible
@function get-card-gutter-width($max-card-count, $visible-card-count) {
  @return if($visible-card-count < $max-card-count, $gutter-width-small, $gutter-width-large);
}

// Return a breakpoint value in pixels where the card list is wide enough to show
// all the visible cards including a card peeking in from the right at max width
// except for the peeking card whose right edge ends up slightly off the viewport to the right.
// This is the point where if the viewport were to become slightly wider it would be hard
// to tell that there were more cards you could scroll to to the right of the viewport.
// We return pixels because the media query mixins only accept breakpoints in pixels.
@function get-max-breakpoint($max-card-count, $visible-card-count) {
  @if type-of($max-card-count) != 'number' or unitless($max-card-count) == false {
    @error "$max-card-count must be a unitless number.";
  }

  @if type-of($visible-card-count) != 'number' or unitless($visible-card-count) == false {
    @error "$visible-card-count must be a unitless number.";
  }

  $visible-card-count-including-peek-card: $visible-card-count + 1;
  $max-card-width: get-max-card-width($max-card-count);
  $total-card-width-including-peek-card: $visible-card-count-including-peek-card * $max-card-width;

  // Calculate the total visible gutter width including the left layout gutter
  $visible-gutter-count: $visible-card-count-including-peek-card - 1;
  $card-gutter-width: get-card-gutter-width($max-card-count, $visible-card-count);
  $total-gutter-width: $layout-gutter-width + $visible-gutter-count * $card-gutter-width;

  // In order to calculate a breakpoint where the right edge of the final card is rendered a little outside
  // the right edge of the viewport we need to subtract a small width because we don't want the card's right
  // edge to hit the right edge of the viewport exactly. We reuse the peek width variable for this little
  // extra space.
  $negative-right-edge: $peek-width;

  @return rem-to-px($total-card-width-including-peek-card + $total-gutter-width - $negative-right-edge);
}

@function get-fluid-card-width($max-card-count, $visible-card-count, $include-peek-width) {
  @if type-of($max-card-count) != 'number' or unitless($max-card-count) == false {
    @error "$max-card-count must be a unitless number.";
  }

  @if type-of($visible-card-count) != 'number' or unitless($visible-card-count) == false {
    @error "$visible-card-count must be a number.";
  }

  @if type-of($include-peek-width) != 'bool' {
    @error "$include-peek-width must be a boolean value.";
  }

  // The initial card width is how wide the card would be if there were no gutters
  // and no next card to peek.
  $initial-card-width: math.div(100%, $visible-card-count);

  // Calculate the total gutter width including the layout gutters
  $visible-gutter-count: $visible-card-count - 1;
  $card-gutter-width: get-card-gutter-width($max-card-count, $visible-card-count);
  $total-gutter-width: $total-layout-gutter-width + $visible-gutter-count * $card-gutter-width;

  // The final card width is calculated by dividing the total gutter width and peek width equally
  // between each card and then subtracting that value from the initial card width.
  // We do this because the gutters are always visible and we only want to calculate how wide the
  // actual cards between those gutters should be.
  $total-gutter-width-per-card: math.div($total-gutter-width, $visible-card-count);
  $peek-width-per-card: if($include-peek-width, math.div($peek-width, $visible-card-count), 0px);

  @return calc(#{$initial-card-width} - #{$total-gutter-width-per-card} - #{$peek-width-per-card});
}

@function get-max-card-width($max-card-count) {
  @if type-of($max-card-count) != 'number' or unitless($max-card-count) == false {
    @error "$max-card-count must be a number.";
  }

  // The initial card width is how wide the card would be if there were no gutters
  // and no next card to peek.
  $initial-card-width: math.div($layout-width-including-spacing, $max-card-count);

  // Calculate the total gutter width including the layout gutters
  $visible-gutter-count: $max-card-count - 1;
  $total-gutter-width: $total-layout-gutter-width + $visible-gutter-count * $gutter-width-large;

  // The final card width is calculated by dividing the total gutter width equally between each card
  // and then subtracting that value from the initial card width.
  // We do this because the gutters are always visible and we only want to calculate how wide the
  // actual cards between those gutters should be.
  $total-gutter-width-per-card: math.div($total-gutter-width, $max-card-count);

  @return $initial-card-width - $total-gutter-width-per-card;
}

.card-list {
  position: relative;

  // We set z index to 0 so z indexes of this element's children will be local to this element
  z-index: 0;

  // Make the slides scrollable on small screens
  overflow-x: auto;
  overflow-y: hidden;

  // Necessary to make overflow scrolling work in Safari
  -webkit-overflow-scrolling: touch;

  // 2015 spec
  scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(100%);

  // 2018 spec
  scroll-snap-type: x mandatory;

  // Cancel the layout padding since the card list needs to be as wide as the viewport
  // when the viewport is smaller than the layout width in order for the media queries
  // to work correctly. If we had been able to use container queries we wouldn't need
  // to do this.
  margin-left: size(-$layout-size-horizontal);
  margin-right: size(-$layout-size-horizontal);

  // Add enough padding above and below to make room for the card's box shadows.
  // We cancel the padding using negative margins so there won't be a lot of
  // empty space above and below the card list.
  // We don't need to do the same to the left and right because we've effectively
  // already done that when we cancelled the horizontal spacing.
  padding-top: size(2);
  margin-top: size(-2);
  padding-bottom: size(3);
  margin-bottom: size(-1);
}

.card-list__list {
  display: flex;

  // If wrap-class is applied we set flex-wrap on the largest breakpoint
  // from where a 3 card-set becomes scrollable
  .card-list--wrap.card-list--length-3 & {
    @include mq-gte(get-max-breakpoint(3, 2)) {
      flex-wrap: wrap;
    }
  }

  // If wrap-class is applied we set flex-wrap on the largest breakpoint
  // from where a 4 card-set becomes scrollable
  .card-list--wrap.card-list--length-4 & {
    @include mq-gte(get-max-breakpoint(4, 3)) {
      flex-wrap: wrap;
    }
  }

  // Use pseudo elements to add layout gutters to the left and right of the list content.
  // Using just padding or margins wouldn't work since this flex element is only as wide
  // as the viewport and padding or margin to the right would not end up to the right of
  // the rightmost children as one might expect.
  &:before,
  &:after {
    display: block;
    content: '';
    width: $layout-gutter-width;

    // See comment in `.card-list__card` for why we use `flex-shrink: 0`
    flex-shrink: 0;
  }
}

.card-list__card {
  position: relative;

  // We use `flex-shrink: 0` because if we say the card should be ~50% wide we don't
  // want the flex container to automatically shrink the card width for us.
  flex-shrink: 0;

  // Add a gutter between each card
  & + & {
    margin-left: $gutter-width-small;
  }

  // If wrap-class is applied we add a margin-left to every 4th
  // card so that they are aligned with the first card
  // We also add margin top to all cards after the 3rd,
  // so that we get a gap between the wrapped rows
  .card-list--wrap.card-list--length-3 &:nth-child(3n + 4) {
    @include mq-gte(get-max-breakpoint(3, 2)) {
      margin-left: $layout-gutter-width;
    }
  }
  .card-list--wrap.card-list--length-3 &:nth-child(1n + 4) {
    @include mq-gte(get-max-breakpoint(3, 2)) {
      margin-top: size(4);
    }
  }

  // If wrap-class is applied we add a margin-left to every 5th
  // card so that they are aligned with the first card
  // We also add margin top to all cards after the 4th,
  // so that we get a gap between the wrapped rows
  .card-list--wrap.card-list--length-4 &:nth-child(4n + 5) {
    @include mq-gte(get-max-breakpoint(4, 3)) {
      margin-left: $layout-gutter-width;
    }
  }
  .card-list--wrap.card-list--length-4 &:nth-child(1n + 5) {
    @include mq-gte(get-max-breakpoint(4, 3)) {
      margin-top: size(4);
    }
  }
}

.card-list__card--equal-height {
  // Make the content of each card as tall as the tallest card per row
  & > * {
    min-height: 100%;
  }
}

// The peek buttons are absolutely positioned over each card item and its gutters.
// The button is hidden when a card is completely visible.
.card-list__peek-button {
  height: 100%;
  left: -$gutter-width-small;
  position: absolute;
  top: 0;
  width: calc(100% + #{2 * $gutter-width-small});

  .card-list__card:first-child & {
    left: 0;
    width: calc(100% + #{$gutter-width-small});
  }

  .card-list__card:last-child & {
    width: calc(100% + #{$gutter-width-small});
  }

  // The peek button only makes sense when the app is running
  .no-js & {
    display: none;
  }
}

// The card sets are used as scroll snap points instead of each card itself
// being a scroll snap point. We do this because when the viewport is wide enough
// that two or three cards can be fully visible on screen at once we don't want
// to snap to a specific card since that might make the card to its left and
// right be partially scrolled off screen.
.card-list__card-set {
  position: absolute;
  top: 0;
  bottom: 0;
  scroll-snap-align: center;
  scroll-snap-stop: always;

  // Render the card set behind all other elements in the card list
  // and make it non-interactive.
  z-index: -1;
  pointer-events: none;
}

.card-list--length-2 {
  .card-list__card {
    // One card visible
    // To avoid that the card is getting to much width on smaller devices,
    // we calculate the width based on three cards instead of two.
    // This is currently only used for SunCard and MoonCard
    max-width: get-max-card-width(3);
    width: get-fluid-card-width(3, 1, true);

    @include mq-gte($mq-675) {
      max-width: get-max-card-width(2);
      width: get-fluid-card-width(2, 1, true);
    }

    // Two cards visible
    @include mq-gte(get-max-breakpoint(2, 1)) {
      width: get-fluid-card-width(2, 2, false);
    }
  }

  // Two cards visible
  @include mq-gte(get-max-breakpoint(2, 1)) {
    // Increase the gutter between each card
    .card-list__card + .card-list__card {
      margin-left: $gutter-width-large;
    }

    // Increase the size of the peek button to account for the larger gutter size
    .card-list__peek-button {
      left: -$gutter-width-large;
      width: calc(100% + #{2 * $gutter-width-large});
    }

    .card-list__card:first-child .card-list__peek-button {
      left: 0;
      width: calc(100% + #{$gutter-width-large});
    }

    .card-list__card:last-child .card-list__peek-button {
      width: calc(100% + #{$gutter-width-large});
    }
  }
}

.card-list--length-3 {
  .card-list__card {
    // One card visible
    max-width: get-max-card-width(3);
    width: get-fluid-card-width(3, 1, true);

    // Two cards visible
    @include mq-gte(get-max-breakpoint(3, 1)) {
      width: get-fluid-card-width(3, 2, true);
    }

    // Three cards visible
    @include mq-gte(get-max-breakpoint(3, 2)) {
      width: get-fluid-card-width(3, 3, false);
    }
  }

  // Three cards visible
  @include mq-gte(get-max-breakpoint(3, 2)) {
    // Increase the gutter between each card
    .card-list__card + .card-list__card {
      margin-left: $gutter-width-large;
    }

    // Increase the size of the peek button to account for the larger gutter size
    .card-list__peek-button {
      left: -$gutter-width-large;
      width: calc(100% + #{2 * $gutter-width-large});
    }

    .card-list__card:first-child .card-list__peek-button {
      left: 0;
      width: calc(100% + #{$gutter-width-large});
    }

    .card-list__card:last-child .card-list__peek-button {
      width: calc(100% + #{$gutter-width-large});
    }
  }
}

.card-list--length-4 {
  .card-list__card {
    // One card visible
    max-width: get-max-card-width(4);
    width: get-fluid-card-width(4, 1, true);

    // Two cards visible
    @include mq-gte(get-max-breakpoint(4, 1)) {
      width: get-fluid-card-width(4, 2, true);
    }

    // Three cards visible
    @include mq-gte(get-max-breakpoint(4, 2)) {
      width: get-fluid-card-width(4, 3, true);
    }

    // Four cards visible
    @include mq-gte(get-max-breakpoint(4, 3)) {
      width: get-fluid-card-width(4, 4, false);
    }
  }

  // Four cards visible
  @include mq-gte(get-max-breakpoint(4, 3)) {
    // Increase the gutter between each card
    .card-list__card + .card-list__card {
      margin-left: $gutter-width-large;
    }

    // Increase the size of the peek button to account for the larger gutter size
    .card-list__peek-button {
      left: -$gutter-width-large;
      width: calc(100% + #{2 * $gutter-width-large});
    }

    .card-list__card:first-child .card-list__peek-button {
      left: 0;
      width: calc(100% + #{$gutter-width-large});
    }

    .card-list__card:last-child .card-list__peek-button {
      width: calc(100% + #{$gutter-width-large});
    }
  }
}
