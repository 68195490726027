@use '../../styles/yr' as *;

$mobile-size-ish: $mq-675;
$tablet-size-ish: $mq-990;

.route-forecast-item {
  background-color: var(--color-background-base);
  border-bottom: $divider-solid;
  display: grid;
  grid-template-columns: minmax(size(24), 1fr) size(96);
  grid-template-areas: 'location forecast';
  // Make z index of descendants local
  position: relative;
  z-index: 0;

  @include hover {
    background-color: var(--color-background-muted-core-blue);
  }

  @include mq-lte($tablet-size-ish) {
    padding: 0 size(2);
    display: flex;
    flex-direction: column;
    margin-bottom: size(1);
    box-shadow: $theme-box-shadow;

    @include hover {
      background-color: var(--color-background-base);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.route-forecast-item__location-and-warning {
  padding-left: size(2);
  padding-right: size(2);
  grid-area: location;
  display: flex;
  align-items: center;

  @include mq-lte($tablet-size-ish) {
    justify-content: space-between;
    padding: size(1) 0;
  }
}

.route-forecast-item__location-link {
  text-decoration: none;
  word-break: break-all;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // Render in front of most of the content in the list item
    z-index: 1;

    // We remove the default focus style from the link itself
    // and add it to the larger pseudo-element itself so the whole
    // list item gets a focus outline when the date link is focused.
    &:focus {
      outline: 0;

      &:after {
        @include focus-style;
      }
    }
  }
}

.route-forecast-item__location-name {
  display: inline-block;
  margin-right: 1ch;
}

.route-forecast-item__header {
  display: none;
  @include mq-lte($tablet-size-ish) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: size(0.5) 0;
    border-bottom: $divider-solid;
  }
}

.route-forecast-item__header-weather {
  display: none;
  @include mq-lte($tablet-size-ish) {
    display: grid;
    grid-template-areas: 'symbol temperature wind';
    grid-template-columns: size(5) size(6) size(16);
    column-gap: size(2);
    @include mq-lte($mobile-size-ish) {
      grid-template-columns: size(5) size(4) size(12);
      column-gap: size(0.5);
    }
  }
}

.route-forecast-item__header-symbol {
  grid-area: symbol;
  align-self: end;
}

.route-forecast-item__header-temperature {
  align-self: end;
  grid-area: temperature;
  text-align: right;
}

.route-forecast-item__header-wind {
  align-self: end;
  grid-area: wind;
  text-align: right;
}

.route-forecast-item__forecast-list {
  grid-area: forecast;
  display: grid;
  grid-template-columns: size(21) size(21) size(21) size(21);
  grid-template-areas: 'interval-1 interval-2 interval-3 interval-4';
  column-gap: size(4);
  @include mq-lte($tablet-size-ish) {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: none;
  }
}

.route-forecast-item__forecast-container {
  min-width: size(21);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: size(2);
  padding-bottom: size(2);

  // Since IE11 does not support auto-layout we need to specify grid areas
  // for the intervals
  &:nth-child(1) {
    grid-area: interval-1;
  }

  &:nth-child(2) {
    grid-area: interval-2;
  }

  &:nth-child(3) {
    grid-area: interval-3;
  }

  &:nth-child(4) {
    grid-area: interval-4;
  }

  @include mq-lte($tablet-size-ish) {
    min-width: auto;
    justify-content: space-between;
    padding-top: size(0.5);
    padding-bottom: size(0.5);
    position: relative;
  }
}

.route-forecast-item__forecast-mobile-divider {
  border-top: $divider-solid;
  display: flex;
  position: absolute;
  left: size(-2);
  right: size(-2);
  top: rem(-1px);
  @include mq-gt($tablet-size-ish) {
    display: none;
  }
}

.route-forecast-item__forecast {
  display: grid;
  grid-template-areas: 'symbol temperature wind';
  grid-template-columns: size(5.5) size(4) size(11.5);
  @include mq-lte($tablet-size-ish) {
    grid-template-columns: size(5) size(6) size(16);
    column-gap: size(2);
    @include mq-lte($mobile-size-ish) {
      grid-template-columns: size(5) size(4) size(12);
      column-gap: size(0.5);
    }
  }
}

.route-forecast-item__forecast--empty {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: size(5);
}

.route-forecast-item__interval {
  @include mq-gt($tablet-size-ish) {
    @include nrk-sr();
  }
}

.route-forecast-item__symbol {
  height: size(5);
  width: size(5);
  grid-area: symbol;
  align-self: center;
}

// IE11 needs the symbol to be inline to not
// think it is way higher than it really is.
.route-forecast-item__symbol-inner {
  display: inline;
}

.route-forecast-item__temperature {
  align-self: center;
  grid-area: temperature;
  text-align: right;
}

.route-forecast-item__wind {
  align-self: center;
  grid-area: wind;
  text-align: right;
  padding-right: size(1);
}
