@use '../../styles/yr' as *;

.clippy {
  align-items: flex-end;
  bottom: size(4);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  right: size(2);
  z-index: $z-index-clippy;
}

.clippy--open {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: size(2) size(2) size(4) size(2);

  &:after {
    @include backdrop;

    content: '';
    z-index: -1;
  }
}

.clippy__button {
  align-items: center;
  border-radius: size(3);
  box-shadow: $theme-box-shadow-elevated;
  display: flex;
  height: size(6);
  padding: 0 size(1.5);
  flex-shrink: 0;

  background-color: var(--color-button-help-bg-rest);

  &:hover {
    background-color: var(--color-button-help-bg-hover);
  }

  &:active {
    background-color: var(--color-button-help-bg-pressed);
  }

  // Render the button after the body
  order: 2;
}

.clippy__button-icon {
  margin-left: size(0.5);
}

.clippy__body {
  background-color: var(--color-background-base);
  border-radius: size(1);
  box-shadow: $theme-box-shadow-elevated;
  margin-bottom: size(2);
  max-width: size(39);
  width: 100%;
  overflow: hidden;
  overflow-y: auto;

  // Render the body before the button
  order: 1;
}

.clippy__list-item {
  &:nth-child(n + 2) {
    border-top: $divider-solid;
  }
}

.clippy__option {
  position: relative;
  padding-top: size(2);
  padding-right: size(2 + 2 + 2); // Padding + icon size + padding
  padding-bottom: size(2);
  padding-left: size(2 + 3 + 2); // Padding + icon size + padding

  &:hover,
  &:focus-within {
    background-color: var(--color-background-muted-core-blue);
  }
}

.clippy__option--has-subtitle {
  padding-top: size(1.5);
  padding-bottom: size(1.5);
}

.clippy__option-icon {
  position: absolute;
  top: 50%;
  left: size(2);
  transform: translateY(-50%);
}

.clippy__option-title {
  text-align: left;
  text-decoration: none;

  // Make the entire item clickable by positioning the "after" pseudo-element
  // of the clickable title link/button over the entire item.
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.clippy__option-type-icon {
  position: absolute;
  top: 50%;
  right: size(2);
  transform: translateY(-50%);
}

.clippy__modal-illustration {
  width: 100%;
  height: auto;
}

.clippy__modal-body {
  padding: size(3) size(2);
  text-align: center;

  // Reducing top padding when there is an illustration
  // to reduce the gap between the illustration and the body.
  .clippy__modal--has-illustration & {
    padding-top: size(2);
  }
}

.clippy__modal-title {
  margin-bottom: size(1);
}

.clippy__modal-toolbar {
  display: inline-flex;
  margin-top: size(3);
  justify-content: center;
  min-width: size(11);
}
