@use '../../styles/yr' as *;

$underline: var(--color-fill-accent-core-blue);

$header-height: size(6);
$border-height: rem(3px);

// Increase menu link padding on larger screens.
// This should occur at the same time as we switch to the larger NowHero design.
$mq-large-menu-link-padding-gt: 950px;

$z-index-scroll-button: 1;
$z-index-border: 2;
$z-index-active-link-border: 3;

.location-header {
  position: relative;

  // Contain z indexes
  z-index: 0;

  // Add a border below the header.
  // We use a pseudo-element instead of a border because we don't
  // want to affect the height.
  &:after {
    background-color: var(--color-background-muted-core-blue);
    bottom: 0;
    content: '';
    display: block;
    height: $border-height;
    left: 0;
    position: absolute;
    right: 0;
    z-index: $z-index-border;
  }
}

.location-header__content {
  position: relative;

  // We give this element a fixed height and use "overflow: hidden"
  // in order to hide the horizontal scrollbar of the scrollable
  // ".location-header__list" element.
  height: $header-height;
  overflow: hidden;

  // In no-js mode we want the list to wrap instead of being scrollable
  .no-js & {
    height: auto;
    overflow: visible;
  }
}

.location-header__scroll-left-button,
.location-header__scroll-right-button {
  background-color: var(--color-background-base);
  align-items: center;
  color: var(--color-stroke-active);
  display: flex;
  height: $header-height;
  justify-content: center;
  position: absolute;
  top: 0;
  width: size(5);
  z-index: $z-index-scroll-button;

  // The default tap highlight color on iOS is a bit distracting
  // on these buttons so we hide it.
  -webkit-tap-highlight-color: transparent;

  // Hide the element but make it still accessible to screen readers
  // so the button won't seemingly appear and disappear at random.
  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }
}

.location-header__scroll-left-button {
  left: 0;
}

.location-header__scroll-right-button {
  right: 0;
}

.location-header__list {
  display: flex;

  // Make this element tall enough that the horizontal scrollbar
  // is rendered below the visible height of the parent
  // ".location-header__content" element.
  height: $header-height + rem(100px);

  // Make the element horizontally scrollable when we have more menu items
  // than we can show in a single row.
  overflow-x: scroll;

  // In no-js mode we want the list to wrap instead of being scrollable
  .no-js & {
    flex-wrap: wrap;
    height: auto;
    overflow-x: visible;
  }
}

.location-header__menu-link {
  display: flex;
  align-items: center;
  height: $header-height;
  padding: 0 size(2);
  position: relative;
  text-decoration: none;

  @include mq-gt($mq-large-menu-link-padding-gt) {
    padding: 0 size(3);
  }

  // Add a dark blue line below the current page's link
  &[aria-current='page']:after {
    background-color: $underline;
    bottom: 0;
    content: '';
    display: block;
    height: $border-height;
    left: 0;
    position: absolute;
    right: 0;
    z-index: $z-index-active-link-border;
  }

  @include hover {
    &:not([aria-current='page']) {
      color: var(--color-text-active);
      background-color: var(--color-background-muted-core-blue);
    }
  }
}

.location-header__menu-item-text {
  align-items: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  flex-direction: column;
}

// The active menu label is bolder than the inactive menu label
// which makes it a few pixels wider.
// To prevent a visual shift when switching between the bold and
// non-bold text we always render the bold active menu label
// but hide it with `visibility: hidden` when the menu link
// is not active.
.location-header__active-menu-label {
  color: var(--color-text-active);
  // Hide the active text when the parent link is inactive
  .location-header__menu-link:not([aria-current='page']) & {
    visibility: hidden;
    height: 0;
  }
}

.location-header__inactive-menu-label {
  // Hide the inactive text when the parent link is active
  .location-header__menu-link[aria-current='page'] & {
    visibility: hidden;
    height: 0;
  }
}

.location-header__menu-new-tag {
  margin-left: size(1);
}
