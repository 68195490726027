@use '../../styles/yr' as *;

.location-details-panel__button {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: size(1);
  padding: size(2);
  width: 100%;

  &:after {
    display: block;
    content: '';
    position: absolute;
    right: size(2);
    bottom: -1px;
    left: size(2);
    border-bottom: $divider-solid;
  }

  @include hover {
    background-color: var(--color-background-hover);
  }
}

.location-details-panel__button-icon {
  color: var(--color-stroke-subtle);
  margin-right: size(1);

  .location-details-panel[data-is-favourite='true'] & {
    color: var(--color-fill-selected);
  }
}

.location-details-panel__details {
  padding: 0 size(2) size(2) size(2);
}
