@use "sass:math";
@use '../../styles/yr' as *;

.menu__dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  backdrop {
    @include backdrop;
  }
}

.menu__core-dialog {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.menu__dialog--visible .menu__dialog-container {
  transform: translateX(0);
  top: 0;
}

.menu__dialog-container {
  position: absolute;
  background-color: var(--color-background-base);
  border: 0;
  box-shadow: $theme-box-shadow-elevated;
  height: 100%;
  margin: 0 0 0 1em;
  padding: 1em;
  z-index: $z-index-modal-dialog;
  top: 0;
  right: 0;
  overflow-y: auto;

  transform: translateX(100%);
  transition: transform 300ms $menu-dialog-ease-in;

  @include mq-lte($mq-675) {
    width: 93%;
  }
}

.menu__dialog-content {
  margin: 5rem 1rem 0 1rem;
}

.menu__served-by {
  margin-top: 5rem;
}

.menu__info-panel {
  margin: 0;
  padding: 0.5rem;
}

.menu__info-panel-content {
  margin: 4rem 2rem 4rem 2rem;
  width: 30rem;
}

.menu__close-button {
  right: size(2);
  position: absolute;
  top: size(2);
}

.menu__list-item {
  border-top: solid 1px var(--color-stroke-subtle-gray);
}

.menu__list-item:last-child {
  border-bottom: solid 1px var(--color-stroke-subtle-gray);
}

.menu__link {
  $height: size(7);
  display: block;
  text-decoration: none;
  padding: math.div($height - $text-4-line-height, 2) size(1);
  cursor: pointer;

  @include hover {
    background-color: var(--color-background-muted-core-blue);
  }
}

.menu__language-selector {
  $height: size(7);
  position: relative;
  padding: math.div($height - $text-4-line-height, 2) size(1);
  cursor: pointer;

  // Give the language selector the same background as the links when hovered,
  // or when the <select> child is focused using a keyboard.
  @include hover($focus-within: true) {
    background-color: var(--color-background-muted-core-blue);
  }

  // Add the focus style around the entire language selector when the <select>
  // child is focused using a keyboard.
  &:focus-within {
    @include focus-style;
  }
}

.menu__language-selector-icon {
  color: var(--color-stroke-subtle);
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  right: size(1);
}

.menu__language-selector-options {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  // Make it possible to style the element with "height: 100%" in Safari
  -webkit-appearance: none;

  // Prevent iOS from zooming in when the <select> element is focused
  font-size: 16px;
}
