@use "sass:math";
@use '../../styles/yr' as *;

.icon-button {
  // Set up transparent border that is used later
  border: 1px solid transparent;

  &[data-hide-border='true'] {
    border: 0;
  }

  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: size(1);
  &[data-shape='rounded'] {
    border-radius: rem(100px);
  }
}

.icon-button[data-type='highlight'] {
  color: var(--color-button-highlight-fg-rest);
  background-color: var(--color-button-highlight-bg-rest);
  border-color: var(--color-button-highlight-bg-rest);

  @include hover {
    background-color: var(--color-button-highlight-bg-hover);
    border-color: var(--color-button-highlight-bg-hover);
  }

  &[aria-pressed='true'] {
    background-color: var(--color-button-highlight-bg-pressed);
    border-color: var(--color-button-highlight-bg-pressed);
  }

  @include active() {
    background-color: var(--color-button-highlight-bg-pressed);
    border-color: var(--color-button-highlight-bg-pressed);
  }

  &:focus,
  &:focus-within {
    // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
    .is-tabbing & {
      box-shadow: 0 0 0 2px var(--color-background-base) inset;
    }
  }

  &[disabled] {
    color: var(--color-button-highlight-fg-disabled);
    background-color: var(--color-button-highlight-bg-disabled);
    border-color: var(--color-button-highlight-bg-disabled);

    @include hover {
      color: var(--color-button-highlight-fg-disabled);
      background-color: var(--color-button-highlight-bg-disabled);
      border-color: var(--color-button-highlight-bg-disabled);
      cursor: default;
    }
  }

  &[aria-current='true'] {
    background-color: var(--color-button-highlight-bg-hover);
    border-color: var(--color-button-highlight-bg-hover);
  }
}

.icon-button[data-type='primary'] {
  color: var(--color-button-primary-fg-rest);
  background-color: var(--color-button-primary-bg-rest);
  border-color: var(--color-button-primary-border-rest);

  @include hover {
    color: var(--color-button-primary-fg-rest);
    background-color: var(--color-button-primary-bg-hover);
    border-color: var(--color-button-primary-border-rest);
  }

  &[aria-pressed='true'] {
    color: var(--color-button-primary-fg-rest);
    background-color: var(--color-button-primary-bg-pressed);
    border-color: var(--color-button-primary-border-rest);
  }

  @include active() {
    color: var(--color-button-primary-fg-rest);
    background-color: var(--color-button-primary-bg-pressed);
    border-color: var(--color-button-primary-border-rest);
  }

  &:focus,
  &:focus-within {
    color: var(--color-button-primary-fg-rest);
    border-color: transparent;
    background-color: var(--color-button-primary-bg-rest);

    // Since we use a default focus style when navigating with a keyboard, we need to add a fallback border-color for instances where the element is focused with javascript
    .js:not(.is-tabbing) & {
      border-color: var(--color-button-primary-border-rest);
    }
  }

  &[disabled] {
    color: var(--color-button-primary-fg-disabled);
    border-color: var(--color-button-primary-border-disabled);
    background: var(--color-button-primary-bg-rest);

    @include hover {
      color: var(--color-button-primary-fg-disabled);
      border-color: var(--color-button-primary-border-disabled);
      background: var(--color-button-primary-bg-rest);
      cursor: default;
    }
  }

  &[aria-current='true'] {
    color: var(--color-button-primary-fg-rest);
    background-color: var(--color-button-primary-bg-hover);
    border-color: var(--color-button-primary-border-rest);
  }
}

.icon-button[data-type='critical'] {
  color: var(--color-button-critical-fg-rest);
  background-color: var(--color-button-critical-bg-rest, #fff);
  border-color: var(--color-button-critical-border-rest);

  @include hover {
    color: var(--color-button-critical-fg-rest);
    background-color: var(--color-button-critical-bg-hover);
    border-color: var(--color-button-critical-border-rest);
  }

  &[aria-pressed='true'] {
    color: var(--color-button-critical-fg-rest);
    background-color: var(--color-button-critical-bg-pressed);
    border-color: var(--color-button-critical-border-rest);
  }

  @include active() {
    color: var(--color-button-critical-fg-rest);
    background-color: var(--color-button-critical-bg-pressed);
    border-color: var(--color-button-critical-border-rest);
  }

  &:focus,
  &:focus-within {
    color: var(--color-button-critical-fg-rest);
    background-color: var(--color-button-critical-bg-rest);
    border-color: transparent;

    // Since we use a default focus style when navigating with a keyboard, we need to add a fallback border-color for instances where the element is focused with javascript
    .js:not(.is-tabbing) & {
      border-color: var(--color-button-critical-border-rest);
    }
  }

  &[disabled] {
    color: var(--color-button-critical-fg-disabled);
    background-color: var(--color-button-critical-bg-rest);
    border-color: var(--color-button-critical-border-disabled);

    @include hover {
      color: var(--color-button-critical-fg-disabled);
      background-color: var(--color-button-critical-bg-rest);
      border-color: var(--color-button-critical-border-disabled);
      cursor: default;
    }
  }

  &[aria-current='true'] {
    color: var(--color-button-critical-fg-rest);
    background-color: var(--color-button-critical-bg-hover);
    border-color: var(--color-button-critical-border-rest);
  }
}

.icon-button[data-type='subtle'] {
  color: var(--color-button-subtle-fg-rest);
  background-color: transparent;
  border-color: transparent;

  @include hover {
    color: var(--color-button-subtle-fg-rest);
    background-color: var(--color-button-subtle-bg-hover);
    border-color: var(--color-button-subtle-bg-hover);
  }

  &[aria-pressed='true'] {
    color: var(--color-button-subtle-fg-rest);
    background-color: var(--color-button-subtle-bg-pressed);
    border-color: var(--color-button-subtle-bg-pressed);
  }

  @include active() {
    color: var(--color-button-subtle-fg-rest);
    background-color: var(--color-button-subtle-bg-pressed);
    border-color: var(--color-button-subtle-bg-pressed);
  }

  &:focus,
  &:focus-within {
    color: var(--color-button-subtle-fg-rest);
    background-color: transparent;
    border-color: transparent;
  }

  &[disabled] {
    color: var(--color-button-subtle-fg-disabled);
    background-color: transparent;
    border-color: transparent;

    @include hover {
      color: var(--color-button-subtle-fg-disabled);
      background-color: transparent;
      border-color: transparent;
      cursor: default;
    }
  }

  &[aria-current='true'] {
    color: var(--color-button-subtle-fg-rest);
    background-color: var(--color-button-subtle-bg-hover);
    border-color: var(--color-button-subtle-bg-hover);
  }
}

.icon-button[data-size='small'] {
  $height: rem(34px);
  $width: rem(34px);
  height: $height;
  width: $width;
}

.icon-button[data-size='large'] {
  $height: rem(40px);
  $width: rem(40px);
  height: $height;
  width: $width;
}
