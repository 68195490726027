@use '../../styles/yr' as *;

$mq-layout-two-columns: 850px;

$mq-layout-medium: 600px;
$mq-layout-large: 920px;

.page-footer {
  padding-top: size(3);
  padding-bottom: size(10);

  @include mq-gte($mq-layout-two-columns) {
    padding-top: size(6);
    padding-bottom: size(13);
  }
}

.page-footer__grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto size(6) auto size(6) auto size(1) auto size(3) auto;
  grid-template-areas:
    'toolbar'
    '.'
    'links'
    '.'
    'served-by'
    '.'
    'logos'
    '.'
    'text';

  @include mq-gte($mq-layout-large) {
    grid-template-columns: 1fr size(3) 1fr size(3) 1fr size(3) 1fr;
    grid-template-rows: auto size(1) auto size(6) auto;
    grid-template-areas:
      'served-by . . . . . .'
      '. . . . . . .'
      'logos . toolbar toolbar toolbar toolbar toolbar'
      '. . . . . . .'
      'text text text . links links links';
  }
}

.page-footer__served-by {
  grid-area: served-by;
}

.page-footer__logos {
  grid-area: logos;
  display: flex;
  align-items: center;
  height: rem(42px); // Same height as the toolbar buttons
}

.page-footer__logo-link {
  display: block;
  flex-shrink: 0; // Prevent the logos from shrinking
}

.page-footer__logo-link--met {
  margin-left: size(5);
}

.page-footer__text {
  grid-area: text;
}

.page-footer__copyright-text {
  margin-top: size(2);

  @include mq-gte($mq-layout-two-columns) {
    margin-top: size(4);
  }
}

.page-footer__toolbar {
  grid-area: toolbar;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  // Negative margin to counteract the margin of each toolbar item
  margin: size(-1);

  // Add a gap between each toolbar item.
  // Ideally we'd just use `gap: size(2)` if it were supported in all browsers.
  & > * {
    margin: size(1);
  }

  @include mq-gte($mq-layout-large) {
    justify-content: end;
  }
}

.page-footer__links {
  grid-area: links;
  display: grid;
  grid-template-rows: auto size(2) auto size(2) auto size(2) auto;
  grid-template-areas:
    'about'
    '.'
    'apps'
    '.'
    'some'
    '.'
    'other';

  @include mq-gte($mq-layout-medium) {
    grid-template-columns: 1fr size(3) 1fr;
    grid-template-rows: 1fr size(3) 1fr;
    grid-template-areas:
      'about . some'
      '. . .'
      'apps . other';
  }
}

.page-footer__links-group:nth-child(1) {
  grid-area: about;
}

.page-footer__links-group:nth-child(2) {
  grid-area: apps;
}

.page-footer__links-group:nth-child(3) {
  grid-area: some;
}

.page-footer__links-group:nth-child(4) {
  grid-area: other;
}

.page-footer__links-header {
  margin-bottom: size(1);
}

.page-footer__links-list {
  @include mq-lt($mq-layout-large) {
    display: flex;
    flex-wrap: wrap;

    // Negative margin to counteract the margin of the leftmost and rightmost list item
    margin: 0 size(-0.5);
  }

  @include mq-gte($mq-layout-large) {
    // Negative margin to counteract the margins of the first and last list items
    margin: size(-0.5) 0;
  }
}

.page-footer__links-list li {
  @include mq-lt($mq-layout-large) {
    margin: 0 size(0.5);
  }

  @include mq-gte($mq-layout-large) {
    margin: size(0.5) 0;
  }
}

.page-footer__links-list a {
  color: var(--color-text-link);
}
