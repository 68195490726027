@use '../../styles/yr' as *;

$tablet-size-ish: $mq-990;

.route-forecast-list {
  margin-bottom: size(3);
}

.route-forecast-list__locations {
  background-color: var(--color-background-base);
  @include mq-gt($mq-990) {
    margin: 0 size($layout-size-horizontal);
  }
  @include mq-lte($tablet-size-ish) {
    padding: size(1) 0;
  }
}

.route-forecast-list__headers {
  border-bottom: $divider-solid;

  @include mq-lte($tablet-size-ish) {
    display: none;
  }

  margin: 0 size($layout-size-horizontal);
  display: grid;
  grid-template-areas: 'gap header-1 header-2 header-3 header-4';
  grid-template-columns: 1fr size(21) size(21) size(21) size(21);
  column-gap: size(4);
  text-align: center;
}

.route-forecast-list__header-gap {
  grid-area: gap;
}

.route-forecast-list__header {
  padding-top: size(1);
  padding-bottom: size(1);

  // Since IE11 does not support auto-layout we need to specify grid areas
  // for the headings
  &:nth-child(2) {
    grid-area: header-1;
  }

  &:nth-child(3) {
    grid-area: header-2;
  }

  &:nth-child(4) {
    grid-area: header-3;
  }

  &:nth-child(5) {
    grid-area: header-4;
  }
}
