@use "sass:math";

@use '../yr' as *;
@use '@nrk/nrk-sans/nrk-sans';
@use '@nrk/yr-design-tokens/build/scss/variables';

// Update box model
*,
*:after,
*:before {
  box-sizing: border-box;
}

// Inherit all text styles and reset margins and paddings to 0.
* {
  font-size: inherit;
  font-weight: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

// Get rid of Firefox's default focus style
::-moz-focus-inner {
  border: 0;
}

// Custom global focus outline
:focus {
  @include focus-style;
}

html,
body {
  height: 100%;
  width: 100%;
}

html {
  background-color: var(--color-background-base);

  // Create a base font size of 15px
  font-size: math.div(15px, 16px) * 1em;
}

body {
  @include text-4;

  color: var(--color-text-base);
  font-family: NRK Sans Variable, sans-serif; // Same as `FONT_FAMILY` in `global.ts`
  font-weight: $typography-font-weight-normal;
  z-index: 0;
}

a {
  color: inherit;
  text-decoration: underline;

  @include hover {
    text-decoration: none;
  }
}

// Remove the default dotted border below <abbr> elements
abbr {
  text-decoration: none;
}

// Remove list style from lists by default since it looks odd when padding has been reset to 0,
// and because they most likely will need a unique style in any ase.
ol,
ul {
  list-style: none;
}

// Make img and svg blocks to get rid of the bottom gap that occurs
// when they are aligned with the text baseline and to make make them
// them affect the size of wrapper elements so they're easier to position.
img,
svg {
  display: block;
}

// <use> on some platforms causes problems for our global click handler
svg {
  pointer-events: none;
}

// Cancel the default `fill: black` rule
svg {
  fill: none;
}

// Give elements a max width of 100% to prevent them from ever
// growing larger than the viewport.
img {
  max-width: 100%;
}
